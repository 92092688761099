import { useSelector } from 'react-redux'
import { RootState } from '../reducers'

export const selectorProductTypes = (state: RootState) => state.product.productTypes
export const selectorProductCategoriesData = (state: RootState) => state.product.productCategories
export const selectorProductData = (state: RootState) => state.product.product
export const selectorProductsData = (state: RootState) => state.product.products
export const selectorRecipeTemplateData = (state: RootState) => state.product.recipeTemplate
export const selectorCountProduct = (state: RootState) => state.product.countProduct
export const selectorLoadingGetListProducts = (state: RootState) => state.product.loadingGetListProducts
export const selectorLoadingMore = (state: RootState) => state.product.loadingMore

export const useSelectorProductTypes = () => useSelector(selectorProductTypes)
export const useSelectorProductCategoriesData = () => useSelector(selectorProductCategoriesData)
export const useSelectorProductData = () => useSelector(selectorProductData)
export const useSelectorProductsData = () => useSelector(selectorProductsData)
export const useSelectorRecipeTemplateData = () => useSelector(selectorRecipeTemplateData)
export const useSelectorCountProduct = () => useSelector(selectorCountProduct)
export const useSelectorLoadingGetListProducts = () => useSelector(selectorLoadingGetListProducts)
export const useSelectorLoadingMore = () => useSelector(selectorLoadingMore)
