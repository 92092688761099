/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAction } from '@reduxjs/toolkit'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getListStemPalettes, getStemPalette } from 'src/api/stemPalette'
import { Pagination, PaginationParams, StemPalette } from 'src/common/common.interface'
import { stemPaletteAction } from './stemPalette.slice'
import { appAction } from '../app'

function* callGetStemPalettes({ payload }: ReturnType<typeof stemPaletteActionSaga.getStemPalettes>) {
  try {
    yield put(appAction.startLoading())
    const response: Pagination<StemPalette[]> = yield call(getListStemPalettes, payload)
    yield put(stemPaletteAction.setPalettes(response.results))
    yield put(appAction.endLoading())
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getListStemPalettes', error)
  }
}

function* callGetStemPalette({ payload }: ReturnType<typeof stemPaletteActionSaga.getStemPalette>) {
  try {
    yield put(appAction.startLoading())
    const response: StemPalette = yield call(getStemPalette, payload)
    yield put(stemPaletteAction.setCurrentPalette(response))
    yield put(appAction.endLoading())
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getStemPalette', error)
  }
}

export const stemPaletteActionSaga = {
  getStemPalettes: createAction<PaginationParams>('stemPaletteActionSaga/getStemPalettes'),
  getStemPalette: createAction<number>('stemPaletteActionSaga/getStemPalette')
}

export default [
  function* fetchWatcher() {
    yield all([
      takeLatest(stemPaletteActionSaga.getStemPalettes, callGetStemPalettes),
      takeLatest(stemPaletteActionSaga.getStemPalette, callGetStemPalette)
    ])
  }
]
